import { RouteRecordRaw } from 'vue-router'
import BaseLayout from 'layouts/BaseLayout.vue'
import { FEATURE } from 'src/stores/feature'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'rootLayout',
    component: BaseLayout,
    children: [
      {
        path: '',
        name: 'anonLayout',
        component: () => import('layouts/AnonLayout.vue'),
        children: [
          {
            path: '',
            name: 'root',
            component: () => import('pages/main/Index.vue')
          },
          {
            path: 'auth',
            children: [
              {
                path: 'try-login',
                name: 'auth.tryLogin',
                component: () => import('pages/auth/TryLogin.vue')
              },
              {
                path: 'login',
                name: 'auth.login',
                component: () => import('pages/auth/Login.vue')
              },
              {
                path: 'process-logout',
                name: 'auth.processLogout',
                component: () => import('pages/auth/ProcessLogout.vue')
              },
              {
                path: 'forbidden',
                name: 'auth.forbidden',
                component: () => import('pages/auth/Forbidden.vue')
              },
              {
                path: 'forgot-password',
                name: 'auth.forgotPassword',
                component: () => import('pages/auth/ForgotPassword.vue')
              },
              {
                path: 'reset-password/:token',
                name: 'auth.resetPassword',
                props: true,
                component: () => import('pages/auth/ResetPassword.vue')
              },
              {
                path: 'verify-email',
                name: 'auth.verifyEmail',
                meta: { authorize: true, allowInactive: true },
                component: () => import('pages/auth/VerifyEmail.vue')
              },
              {
                path: 'confirm-email',
                name: 'auth.confirmEmail',
                component: () => import('pages/auth/ConfirmEmail.vue')
              },
              {
                path: 'inactive-merchant',
                name: 'auth.inactiveMerchant',
                meta: { authorize: true, allowInactive: true },
                component: () => import('pages/auth/InactiveMerchant.vue')
              }
            ]
          },
          {
            path: 'register',
            children: [
              {
                path: 'language',
                name: 'register.language',
                component: () => import('pages/register/Language.vue')
              },
              {
                path: 'register',
                name: 'register.register',
                component: () => import('pages/register/Register.vue')
              },
              {
                path: 'questions',
                name: 'register.questions',
                component: () => import('pages/register/Questions.vue')
              },
              {
                path: 'verify-info',
                name: 'register.verifyInfo',
                component: () => import('pages/register/VerifyInfo.vue')
              }
            ]
          },
          {
            path: 'about/privacy',
            name: 'about.privacy',
            component: () => import('pages/about/Privacy.vue')
          },
          {
            path: 'about/terms',
            name: 'about.terms',
            component: () => import('pages/about/Terms.vue')
          },
          {
            path: 'docs',
            name: 'docs.index',
            component: () => import('pages/docs/IndexPage.vue')
          }
        ]
      },
      {
        path: '',
        name: 'posLayoutBasis',
        component: () => import('pages/pos/PosLayoutBasis.vue'),
        meta: { authorize: true },
        children: [
          {
            path: '',
            name: 'posLayout',
            component: () => import('pages/pos/PosCashierLayout.vue'),
            children: [
              {
                path: '',
                name: 'posMainLayout',
                component: () => import('pages/pos/shop/PosMainLayout.vue'),
                children: [
                  {
                    path: '',
                    name: 'posMainLayout',
                    children: [
                      {
                        path: '',
                        name: 'shopBaseLayout',
                        component: () => import('pages/pos/shop/ShopBaseLayout.vue'),
                        children: [
                          {
                            path: '',
                            name: 'shopLayout',
                            component: () => import('pages/pos/shop/ShopLayout.vue'),
                            children: [
                              {
                                path: 'pos/shop',
                                name: 'shop.index',
                                components: {
                                  default: () => import('pages/pos/shop/IndexPage.vue'),
                                  rightPanel: () => import('pages/pos/shop/cart/TheCartRouter.vue')
                                }
                              }
                            ]
                          },
                          {
                            path: 'pos/shop/table',
                            name: 'tableLayout',
                            component: () => import('pages/pos/shop/table/TableLayout.vue'),
                            meta: { authorize: FEATURE.TABLE_ORDER },
                            children: [
                              {
                                path: '',
                                name: 'shop.table.index',
                                components: {
                                  default: () => import('pages/pos/shop/table/TableRouterMain.vue'),
                                  rightPanel: () =>
                                    import('pages/pos/shop/table/SelectViewRight.vue')
                                }
                              },
                              {
                                path: ':sourceTableId/:sourceTabId/move',
                                name: 'shop.table.move',
                                props: true,
                                components: {
                                  default: () => import('pages/pos/shop/table/TableRouterMain.vue'),
                                  rightPanel: () => import('pages/pos/shop/table/MoveViewRight.vue')
                                }
                              },
                              {
                                path: ':sourceTableId/:sourceTabId/move/:tableId',
                                name: 'shop.table.move.details',
                                props: true,
                                components: {
                                  default: () => import('pages/pos/shop/table/TableRouterMain.vue'),
                                  rightPanel: () =>
                                    import('pages/pos/shop/table/MoveDetailsViewRight.vue')
                                }
                              },
                              {
                                path: ':tableId/:tabId/payment',
                                name: 'shop.table.payment',
                                props: true,
                                components: {
                                  default: () => import('pages/pos/shop/table/TableRouterMain.vue'),
                                  rightPanel: () =>
                                    import('pages/pos/shop/table/PaymentViewRight.vue')
                                }
                              },
                              {
                                path: ':id/:number/info',
                                name: 'shop.table.info',
                                props: true,
                                components: {
                                  default: () => import('pages/pos/shop/table/TableRouterMain.vue'),
                                  rightPanel: () =>
                                    import('pages/pos/shop/table/TableTabInfoViewRight.vue')
                                }
                              },
                              {
                                path: ':tableId/:tabId/split',
                                name: 'shop.table.split',
                                props: true,
                                components: {
                                  default: () => import('pages/pos/shop/table/TableRouterMain.vue'),
                                  rightPanel: () =>
                                    import('pages/pos/shop/table/SplitTabViewRight.vue')
                                }
                              },
                              {
                                path: ':id',
                                name: 'shop.table.details',
                                props: true,
                                components: {
                                  default: () => import('pages/pos/shop/table/TableRouterMain.vue'),
                                  rightPanel: () =>
                                    import('pages/pos/shop/table/DetailsViewRight.vue')
                                }
                              },
                              {
                                path: 'map/:id',
                                name: 'shop.table.map.info.details',
                                props: true,
                                components: {
                                  default: () => import('pages/pos/shop/table/TableRouterMain.vue'),
                                  rightPanel: () =>
                                    import('pages/pos/shop/table/DetailsViewRight.vue')
                                }
                              },
                              {
                                path: 'map',
                                name: 'shop.table.map',
                                components: {
                                  default: () => import('pages/pos/shop/table/TableRouterMain.vue'),
                                  rightPanel: () =>
                                    import('pages/pos/shop/table/map/SelectMapViewRight.vue')
                                },
                                meta: { authorize: FEATURE.TABLE_ORDER_MAP }
                              },
                              {
                                path: 'map-edit/:sectionId?',
                                name: 'shop.table.map-edit',
                                props: true,
                                components: {
                                  default: () => import('pages/pos/shop/table/TableRouterMain.vue'),
                                  rightPanel: () =>
                                    import('pages/pos/shop/table/map/EditMapViewRight.vue')
                                },
                                meta: { authorize: FEATURE.TABLE_ORDER_MAP_EDIT }
                              }
                            ]
                          },
                          {
                            path: 'pos/shop/parking',
                            name: 'parkingLayout',
                            component: () => import('pages/pos/shop/parking/ParkingLayout.vue'),
                            meta: { authorize: FEATURE.PARKING },
                            children: [
                              {
                                path: '',
                                name: 'shop.parking.index',
                                components: {
                                  default: () =>
                                    import('pages/pos/shop/parking/SelectProductView.vue'),
                                  rightPanel: () => import('pages/pos/shop/parking/SelectView.vue')
                                }
                              },
                              {
                                path: 'payment',
                                name: 'shop.parking.payment',
                                components: {
                                  default: () =>
                                    import('pages/pos/shop/parking/SelectProductView.vue'),
                                  rightPanel: () => import('pages/pos/shop/parking/PaymentView.vue')
                                }
                              },
                              {
                                path: ':number',
                                name: 'shop.parking.details',
                                props: true,
                                components: {
                                  default: () =>
                                    import('pages/pos/shop/parking/SelectProductView.vue'),
                                  rightPanel: () => import('pages/pos/shop/parking/DetailsView.vue')
                                }
                              },
                              {
                                path: ':number/split',
                                name: 'shop.parking.split',
                                props: true,
                                components: {
                                  default: () =>
                                    import('pages/pos/shop/parking/SelectProductView.vue'),
                                  rightPanel: () =>
                                    import('pages/pos/shop/parking/SplitTabViewRight.vue')
                                }
                              }
                            ]
                          }
                        ]
                      },
                      {
                        path: 'pos/index',
                        name: 'pos.index',
                        components: {
                          default: () => import('pages/pos/IndexPage.vue')
                        }
                      },
                      {
                        path: 'terminal/index',
                        name: 'terminal.index',
                        components: {
                          default: () => import('pages/pos/terminal/IndexPage.vue')
                        }
                      },
                      {
                        path: 'standalone/index',
                        name: 'standalone.index',
                        components: {
                          default: () => import('pages/pos/shop/standalone/IndexPage.vue')
                        }
                      },
                      {
                        path: 'login/:module',
                        name: 'login',
                        props: true,
                        components: {
                          default: () => import('pages/pos/LoginPage.vue')
                        }
                      }
                    ]
                  },
                  {
                    path: 'orders',
                    children: [
                      {
                        path: 'current',
                        name: 'ordersLayout',
                        components: {
                          default: () => import('pages/orders/current/OrdersLayout.vue')
                        },
                        children: [
                          {
                            path: '',
                            name: 'orders.current.index',
                            component: () => import('pages/orders/current/OrdersList.vue')
                          },
                          {
                            path: 'settings',
                            name: 'orders.current.settings',
                            component: () =>
                              import('src/pages/orders/current/OrdersPageSettings.vue')
                          },
                          {
                            path: 'details/:ticketType/:id',
                            name: 'orders.current.details',
                            props: true,
                            component: () =>
                              import('src/pages/orders/current/OrdersPageDetails.vue')
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: 'custom-views',
                    name: 'customViewsLayout',
                    components: {
                      default: () => import('pages/customViews/CustomViewsLayout.vue'),
                      meta: { authorize: FEATURE.CUSTOM_VIEWS_EDIT }
                    },
                    children: [
                      {
                        path: '',
                        name: 'customViews.editor',
                        components: {
                          default: () => import('pages/customViews/Editor.vue')
                        }
                      }
                    ]
                  }
                ]
              },
              {
                path: '',
                name: 'menuLayout',
                component: () => import('pages/pos/shop/MenuLayout.vue'),
                children: [
                  {
                    path: 'about/index',
                    name: 'about.index',
                    component: () => import('pages/pos/about/IndexPage.vue')
                  },
                  {
                    path: 'settings/index',
                    name: 'settings.index',
                    component: () => import('src/pages/settings/SettingsPage.vue')
                  },
                  {
                    path: 'transactions',
                    name: 'transactionsLayout',
                    component: () => import('pages/transactions/TransactionsLayout.vue'),
                    children: [
                      {
                        path: '',
                        name: 'transactions.index',
                        component: () => import('src/pages/transactions/TransactionsPage.vue')
                      },
                      {
                        path: ':id',
                        name: 'transaction.details',
                        props: true,
                        component: () => import('src/pages/transactions/TransactionDetailsPage.vue')
                      }
                    ]
                  },
                  {
                    path: 'xreports',
                    children: [
                      {
                        path: 'search',
                        name: 'xreports.search',
                        component: () => import('src/pages/xreports/XReportsPage.vue')
                      },
                      {
                        path: ':id?',
                        name: 'xreports.details',
                        props: true,
                        component: () => import('src/pages/xreports/XReportDetailsPage.vue')
                      }
                    ]
                  },
                  {
                    path: 'xreport/:id?',
                    name: 'xreport.details',
                    redirect: (to) => ({ name: 'xreports.details', params: to.params })
                  },
                  {
                    path: 'zreports',
                    name: 'zReportsLayout',
                    component: () => import('pages/zreports/ZReportsLayout.vue'),
                    children: [
                      {
                        path: '',
                        name: 'zreports.index',
                        component: () => import('src/pages/zreports/ZReportsPage.vue')
                      },
                      {
                        path: ':id',
                        name: 'zreports.details',
                        props: true,
                        component: () => import('src/pages/zreports/ZReportDetailsPage.vue')
                      }
                    ]
                  },
                  {
                    path: 'zdailyreport',
                    name: 'zDailyReportsLayout',
                    component: () => import('pages/zreports/ZReportsLayout.vue'),
                    children: [
                      {
                        path: '',
                        name: 'zdailyreport.index',
                        component: () => import('src/pages/zreports/zdailyreport/ZDailyReportPage.vue')
                      },
                      {
                        path: ':id',
                        name: 'zdailyreport.details',
                        props: true,
                        component: () => import('src/pages/zreports/zdailyreport/ZDailyReportDetailsPage.vue')
                      }
                    ]
                  },
                  {
                    path: 'ticket-queue',
                    name: 'shop.ticketQueue',
                    component: () => import('pages/ticketQueue/index.vue'),
                    meta: { authorize: FEATURE.TICKET_QUEUE_HISTORY },
                    children: [
                      {
                        path: 'history',
                        name: 'shop.ticketQueue.history',
                        component: () =>
                          import('pages/ticketQueue/ticketQueueHistory.vue')
                      },
                      {
                        path: 'current',
                        name: 'shop.ticketQueue.current',
                        component: () =>
                          import('pages/ticketQueue/currentTicketQueue.vue')
                      }
                    ]
                  },
                  {
                    path: 'receipts',
                    name: 'receipts',
                    children: [
                      {
                        path: '',
                        name: 'receipts.index',
                        component: () => import('src/pages/receipts/ReceiptsPage.vue')
                      },
                      {
                        path: 'details/:id',
                        name: 'receipts.details',
                        props: true,
                        component: () => import('src/pages/receipts/ReceiptDetailsPage.vue')
                      },
                      {
                        path: 'refund/:id',
                        name: 'receipts.refund',
                        props: true,
                        component: () => import('src/pages/receipts/RefundRouter.vue')
                      }
                    ]
                  },
                  {
                    path: 'notifications',
                    name: 'notifications',
                    children: [
                      {
                        path: '',
                        name: 'notifications.index',
                        component: () => import('src/pages/notifications/NotificationsPage.vue')
                      },
                      {
                        path: ':id',
                        name: 'notifications.details',
                        props: true,
                        component: () => import('src/pages/notifications/NotificationsPage.vue')
                      }
                    ]
                  },
                  {
                    path: 'periodreports/index',
                    name: 'periodreports.index',
                    component: () => import('src/pages/periodreports/PeriodReportsPage.vue')
                  },
                  {
                    path: 'cashierreport/index',
                    name: 'cashierreport.index',
                    component: () => import('src/pages/cashierreports/CashierReportsPage.vue')
                  },
                  {
                    path: 'articlereport/index',
                    name: 'articlereport.index',
                    component: () => import('src/pages/articlereports/ArticleReportsPage.vue')
                  },
                  {
                    path: 'staffreport/index',
                    name: 'staffreport.index',
                    meta: { authorize: FEATURE.STAFF_LEDGER },
                    component: () => import('src/pages/staffreports/StaffReportsPage.vue')
                  },
                  {
                    path: 'staff-ledger',
                    name: 'staffLedger',
                    meta: { authorize: FEATURE.STAFF_LEDGER },
                    children: [
                      {
                        path: '',
                        name: 'staffLedger.index',
                        component: () => import('pages/staffLedger/IndexPage.vue')
                      },
                      {
                        path: '',
                        name: 'staffLedger.details',
                        component: () => import('pages/staffLedger/DetailsPage.vue')
                      }
                    ]
                  },
                  {
                    path: 'test/debug',
                    name: 'test.debug',
                    component: () => import('pages/test/DebugPage.vue')
                  }
                ]
              }
            ]
          },
          {
            path: 'kiosk',
            name: 'kioskLayout',
            component: () => import('pages/pos/shop/kiosk/KioskLayout.vue'),
            children: [
              {
                path: '',
                name: 'kiosk.index',
                component: () => import('pages/pos/shop/kiosk/IndexPage.vue')
              },
              {
                path: 'shop',
                name: 'kiosk.shop',
                component: () => import('pages/pos/shop/kiosk/ShopPage.vue')
              },
              {
                path: 'info/:id',
                name: 'kiosk.info',
                props: true,
                component: () => import('pages/pos/shop/kiosk/ShopInfoPage.vue')
              },
              {
                path: 'payment',
                name: 'kiosk.payment',
                props: true,
                component: () => import('pages/pos/shop/kiosk/PaymentPage.vue')
              },
              {
                path: 'wait',
                name: 'kiosk.wait',
                component: () => import('pages/pos/shop/kiosk/WaitPage.vue')
              }
            ]
          },
          {
            path: 'col',
            name: 'ConsumerOrderListLayout',
            component: () => import('pages/pos/shop/consumerOrderList/Layout.vue'),
            children: [
              {
                path: '',
                name: 'col.index',
                component: () => import('pages/pos/shop/consumerOrderList/IndexPage.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'pos-printer',
        component: () => import('layouts/AnonLayout.vue'),
        children: [
          {
            path: ':view/:params*',
            name: 'pos.printer',
            props: true,
            component: () => import('pages/pos/printer/PrinterPage.vue')
          }
        ]
      },
      {
        path: 'pos-ext',
        component: () => import('layouts/AnonLayout.vue'),
        children: [
          {
            path: 'consumer-display',
            name: 'posExt.consumerDisplayLayout',
            component: () => import('pages/pos/ext/consumerDisplay/ConsumerDisplayLayout.vue'),
            redirect: { path: '/pos-ext/consumer-display/index' },
            children: [
              {
                path: 'index',
                name: 'posExt.consumerDisplayDefault',
                component: () =>
                  import('pages/pos/ext/consumerDisplay/ConsumerDisplayDefaultPage.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'ext',
        component: () => import('layouts/AnonLayout.vue'),
        children: [
          {
            path: 'fortnox/init/:id',
            name: 'ext.fortnox.init',
            props: true,
            component: () => import('pages/ext/FortnoxInitPage.vue')
          },
          {
            path: 'fortnox/activate',
            name: 'ext.fortnox.activate',
            component: () => import('pages/ext/FortnoxActivatePage.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/demo',
    component: () => import('src/demo/layouts/DemoLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('src/demo/layouts/MainLayout.vue'),
        children: [{ path: '', component: () => import('src/demo/pages/IndexPage.vue') }]
      },
      {
        path: 'authentication',
        component: () => import('src/demo/layouts/MainLayout.vue'),
        children: [
          { path: 'login', component: () => import('src/demo/pages/LoginPage.vue') },
          { path: 'register', component: () => import('src/demo/pages/RegisterPage.vue') },
          { path: 'companyInfo', component: () => import('src/demo/pages/CompanyInfoPage.vue') },
          { path: 'selectLanguage', component: () => import('src/demo/pages/SelectLanguage.vue') },
          { path: 'verification', component: () => import('src/demo/pages/VerificationPage.vue') },
          { path: 'onboardingPage', component: () => import('src/demo/pages/OnboardingPage.vue') },
          {
            path: 'WaitForActivation',
            component: () => import('src/demo/pages/WaitForActivation.vue')
          }
        ]
      },
      {
        path: 'pay',
        component: () => import('src/demo/layouts/PaymentLayout.vue'),
        children: [
          { path: 'payment', component: () => import('src/demo/pages/PaymentPage.vue') },
          {
            path: 'payment-complete',
            component: () => import('src/demo/pages/PaymentCompletePage.vue')
          }
        ]
      },
      {
        path: 'store',
        component: () => import('src/demo/layouts/StoreLayout.vue'),
        children: [{ path: 'products', component: () => import('src/demo/pages/ProductsPage.vue') }]
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
