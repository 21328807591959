import payment from './shopPayment'

export default {
  openCart: 'Kundvagn',
  cart: 'Kundvagn',
  cancelPurchase: 'Avbryt köp',
  amount: 'Belopp',
  total: 'Total',
  customAmount: 'Eget belopp',
  openAmount: 'Öppet belopp',
  splitPaymentAmount: 'Delbetala, belopp',
  partialPayment: 'Delbetala',
  partialAmount: 'Delbelopp',
  prePaid: 'Förbetalt',
  remainingAmount: 'Återstående',
  notes: 'Notering',
  price: 'Pris',
  quantity: 'Antal',
  refund: 'Återbetala',
  auth: {
    login: 'Logga in',
    location: 'Plats',
    staff: 'Personal',
    choose: 'Välj'
  },
  categoryOther: 'Övrigt',
  categoryAll: 'Allt',
  customize: 'Anpassa',
  discount: 'Rabatt',
  coupon: 'Kupong',
  discountPercentage: 'Procent',
  discountAmount: 'Belopp',
  changePettyCash: 'Ändra växelkassa',
  pettyCash: 'Växelkassa',
  openDrawer: 'Öppna kassalåda',
  delivery: 'Leverans',
  eatHere: 'Äta här',
  takeAway: 'Ta med',
  pager: 'Puck',
  emptyCart: 'Ingenting är valt',
  buttonPayment: 'Betalning',
  payment,
  error: {
    paymentMethodUnavailable: 'Betalmedel är inte tillgängligt',
    paymentMethodBusy: 'Betalmedel är upptaget'
  },
  confirmation: 'Bekräftelse',
  consumerName: 'Namn',
  consumerPhone: 'Telefon',
  consumerAddress: 'Adress',
  consumerZip: 'Postnr',
  consumerCity: 'Stad',
  parking: {
    addSpots: 'Lägg till platser',
    parkOrder: 'Parkera order',
    parkCartTitle: 'Park {tabNumber} cart',
    openCart: 'Park {tabNumber} cart',
    splitTabTitle: 'Dela park {tabNumber}',
    splitTabName: 'Park {tabNumber}',
    splitTabNoTarget: 'Väl målpark nedan',
  },
  lastPayment: {
    link: 'Visa senaste betalning',
    title: 'Senaste betalning',
    order: 'Order',
    tryResolve: 'Försök avsluta betalning',
    status: {
      created: 'Skapd',
      paid: 'Betald',
      declined: 'Nekad',
      cancelledByMerchant: 'Avbruten - obestämd',
      cancelledByConsumer: 'Avbruten'
    },
    info: {
      paymentMethod: 'Betalmetod',
      amount: 'Belopp',
      status: 'Status',
      created: 'Skapad'
    }
  }
}
